import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import IconSortSVG from '@pkgs/shared-client/img/icon-sort-inlined.svg';
import BoardsSortMethod from '@pkgs/shared/enums/BoardsSortMethod';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';

interface SortMethods {
	name: SortMethod;
	label: string;
}

type SortMethod = ValueOf<typeof BoardsSortMethod> | ValueOf<typeof ItemsSortMethod>;

interface SVSortDropdownContentProps {
	methods: SortMethods[];
	sortMethod: SortMethod;
	setSortMethod: (sortMethod: SortMethod) => void;
}

const SVSortDropdownContent = ({
	methods,
	sortMethod,
	setSortMethod,
}: SVSortDropdownContentProps) => {
	const viewportName = useViewportName();
	const isSmallNav = viewportName === 'small' || (!viewportName && ViewportKind.MOBILE);

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) =>
				isSmallNav ? (
					<SVIconButton
						src={IconSortSVG}
						Component="button"
						className="dropdown-trigger relative mt-12 text-gray-400 hover:text-gray-200"
						title="Sort saves"
						{...props}
					/>
				) : (
					<button className="dropdown-trigger relative" {...props}>
						<div className="flex cursor-pointer items-center text-gray-400 hover:text-gray-200">
							<IconSortSVG className="h-5 w-5 -sm:h-4 -sm:w-4" />
						</div>
					</button>
				)
			}
			renderContent={() => (
				<SVDropdownContent.Links>
					<SVDropdownContent.Links.ItemLabel>Sort by</SVDropdownContent.Links.ItemLabel>
					{methods.map((sortMethodInfo) => (
						<SVDropdownContent.Links.Item
							isSelected={sortMethod === sortMethodInfo.name}
							key={sortMethodInfo.name}
							onClick={() => setSortMethod(sortMethodInfo.name)}
							className="min-w-[150px] px-2.5"
						>
							<span className="ml-2">{sortMethodInfo.label}</span>
						</SVDropdownContent.Links.Item>
					))}
				</SVDropdownContent.Links>
			)}
		/>
	);
};

export default SVSortDropdownContent;
